.simplebar-scrollbar{
   display: none;
}

.perfil-modal .modal-content {
    background-image: linear-gradient(45deg, #1565C0, #1E88E5);
    color: #FFF;
    margin: 0;
}

.perfil-modal .modal-header {
    border-bottom: 0px solid #141414;
}

.perfil-modal .modal-footer {
    border-top: 0px solid #141414;
}

.perfil-modal .close {
    color: #FFF;
}