.page-load{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    /* background-color: #0c71c3!important; */
    background-image: linear-gradient(45deg, #1565C0, #1E88E5);
    transition: background-color 0.5s linear;
}

.load-page-spinner{
    position: absolute;
    margin-top: 100px;
}