.side-scroll {
    max-height: calc(100vh - 4rem);
    overflow-y: auto;
}

/* Largura da barra de rolagem */
::-webkit-scrollbar {
    width: 0px;
}

.hide{
    display:none;  
}